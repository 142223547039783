import Offer1 from '../images/offer-1.svg';
import Offer2 from '../images/offer-2.svg';
import Offer3 from '../images/offer-3.svg';
import Offer4 from '../images/offer-4.svg';
import Offer5 from '../images/offer-5.svg';
import Offer6 from '../images/offer-6.svg';

const offersToPlanners = [
	{
		icon: Offer1,
		text: 'The opportunity to promote your business under your personal identity;'
	},
	{
		icon: Offer2,
		text: 'Top tier local support for your business;'
	},
	{
		icon: Offer3,
		text: 'Compliance and paraplanning support;'
	},
	{
		icon: Offer4,
		text: 'A research based approved list without platform restrictions.'
	},
	{
		icon: Offer5,
		text: 'Ongoing professional development training;'
	},
	{
		icon: Offer6,
		text: 'A range of services – business development, investment research, technical advice and referral partners;'
	}
];

const howWeHelpList = [
	{
		text: 'Tax-effective investment planning;'
	},
	{
		text: 'Savings and wealth creation strategies;'
	},
	{
		text: 'Cash, fixed interest, property and share investments;'
	},
	{
		text: 'Diversified investment strategies;'
	},
	{
		text: 'Life insurance and financial protection assessment;'
	},
	{
		text: 'Salary packaging;'
	},
	{
		text: 'Master trust services;'
	},
	{
		text: 'Financial planning following redundancy;'
	},
	{
		text: 'Retirement planning;'
	},
	{
		text: 'Access to estate planning, including trusts, power of attorney and will preparation;'
	},
	{
		text: 'Succession planning; and'
	},
	{
		text: 'Savings plans.'
	}
];

export {offersToPlanners, howWeHelpList};
