import React from 'react';
import styled from 'styled-components';

import Button from '../components/Button';
import Container from '../components/Container';
import PageBase from '../components/PageBase';

import homepageFirstSectionImg from '../images/homepageFirstSectionImg.png';
import homepageFourthSectionImg from '../images/homepageFourthSectionImg.png';
import homepageFifthSectionImg from '../images/homepageFifthSectionImg.svg';
import {offersToPlanners, howWeHelpList} from '../helpers';

export default function Home() {
	return (
		<PageBase headerColor="#f8f9fb">
			<StyledFirstSection>
				<Container>
					<div className="header-content">
						<h2>
							<span>Tailored</span> <br /> Financial Solutions
						</h2>
						<p>
							Providing support to Financial Planners through a wide range of services and solutions – tailored to their unique situations.
						</p>

						<Button to="/contact" value="Partner with us" colorScheme="light" size="big" />
					</div>

					<img
						data-sal="fade"
						data-sal-delay="0"
						data-sal-duration="1000"
						data-sal-easing="ease-in-out"
						src={homepageFirstSectionImg}
						alt="Tailored financial support"
					/>
				</Container>
			</StyledFirstSection>
			<StyledSecondSection>
				<Container>
					<div className="what-we-do">
						<h2>What we do</h2>
						<p>
							NEO Financial Solutions is an Australian Financial Services Licensee, whose primary focus is to provide our financial planners
							with the tools and services, which enable them to grow their businesses and do what they do best – provide high quality
							financial planning advice, specific to their client’s situation. All backed by market leading technology.
						</p>
						<Button to="/about-us" value="Learn more about us" colorScheme="light" size="big" />
					</div>

					<div className="what-we-do-offers">
						<h2>AS part of our offer to planners we provide: </h2>
						<ul className="offers">
							{offersToPlanners.map((offer, idx) => (
								<li
									key={
										// eslint-disable-next-line
										`${offer.text}-${idx}`
									}
								>
									<img
										data-sal="fade"
										data-sal-delay={idx * 100}
										data-sal-duration="400"
										data-sal-easing="ease-in-out"
										src={offer.icon}
										alt="Offer"
									/>
									<p>{offer.text}</p>
								</li>
							))}
						</ul>
					</div>
				</Container>
			</StyledSecondSection>

			<StyledThirdSection>
				<img
					data-sal="fade"
					data-sal-delay="100"
					data-sal-duration="1000"
					data-sal-easing="ease-in-out"
					src={homepageFourthSectionImg}
					alt="How we can help"
				/>
				<Container>
					<div className="how-we-help-content">
						<h2>How we help</h2>
						<p>
							NEO Advisers are given the tools and assistance in offering holistic advice in a wide range of integrated financial services,
							including:
						</p>
						<ul className="how-we-help-list">
							{howWeHelpList.map((help, idx) => (
								<li
									key={
										// eslint-disable-next-line
										`${help.text}-${idx}`
									}
								>
									<span className="list-indicator" />
									{help.text}
								</li>
							))}
						</ul>
					</div>
				</Container>
			</StyledThirdSection>
			<StyledFourthSection>
				<Container>
					<img
						data-sal="slide-right"
						data-sal-delay="100"
						data-sal-duration="1000"
						data-sal-easing="ease-in-out"
						src={homepageFifthSectionImg}
						alt="Lets have a chat!"
					/>
					<div className="curious-content">
						<div className="curious-text">
							<h2>
								Curious? <br /> Let's have a chat!
							</h2>
							<p>
								If you are a Financial Planning Professional looking for a new AFSL or interested in understanding more about our service
								offerings, please contact us for further information about how we can help you.
							</p>
						</div>
						<div className="chat-buttons">
							<Button to="/contact" value="Partner with us" colorScheme="dark" size="big" className="partner-with-us-btn" />
							<Button href="tel:+610894447494" value="Call us (08) 9444 7494" colorScheme="dark" size="big" className="call-us-btn" />
						</div>
					</div>
				</Container>
			</StyledFourthSection>
			<StyledFifthSection>
				<Container>
					<div className="focus-content">
						<h2>What we focus on</h2>
						<p>
							NEO Advisers know that the key to providing high-quality advice is ensuring that the advice is tailored to their client's
							specific situation and needs. By providing tailored advice to their clients, NEO Advisers can develop a strategy that is the
							right fit for you.
						</p>
						<Button to="/about-us" value="Learn more about us" colorScheme="light" size="big" className="learn-more-about-us-btn" />
					</div>

					<section className="focus-list">
						<div
							className="focus-list-item"
							data-sal="slide-left"
							data-sal-delay="100"
							data-sal-duration="500"
							data-sal-easing="ease-out-back"
						>
							<div className="focus-list-item-number-wrapper focus-list-desktop-number">
								<div className="focus-list-item-number">1</div>
							</div>
							<div className="focus-list-item-content">
								<div className="focus-list-item-header">
									<div className="focus-list-item-number focus-list-mobile-number">1</div>
									<h2>Professional advice</h2>
								</div>
								<p>
									NEO Advisers are highly skilled professionals who are backed by stringent education standards. We apply our own training
									to our advisers (on top of industry standards) to ensure that our advisers are highly knowledgeable, well trained and able
									to provide high quality advice.
								</p>
							</div>
						</div>
						<div
							className="focus-list-item"
							data-sal="slide-left"
							data-sal-delay="100"
							data-sal-duration="500"
							data-sal-easing="ease-out-back"
						>
							<div className="focus-list-item-number-wrapper focus-list-desktop-number">
								<div className="focus-list-item-number">2</div>
							</div>
							<div className="focus-list-item-content">
								<div className="focus-list-item-header">
									<div className="focus-list-item-number focus-list-mobile-number">2</div>
									<h2>
										Client - <br /> focused advice
									</h2>
								</div>
								<p>
									NEO Advisers own their individual financial planning practices, which operate within the NEO network. As small business
									owners, our Advisers know that the success of their businesses is reliant on the commitment that they provide to their
									clients.
								</p>
							</div>
						</div>
						<div
							className="focus-list-item"
							data-sal="slide-left"
							data-sal-delay="100"
							data-sal-duration="500"
							data-sal-easing="ease-out-back"
						>
							<div className="focus-list-item-number-wrapper focus-list-desktop-number">
								<div className="focus-list-item-number">3</div>
							</div>
							<div className="focus-list-item-content">
								<div className="focus-list-item-header">
									<div className="focus-list-item-number focus-list-mobile-number">3</div>
									<h2>
										Peace of <br /> mind
									</h2>
								</div>
								<p>
									Our main goal is to help Australians meet their financial and lifestyle goals and protect their families. But we also work
									hard to provide you with peace of mind. Finally, regular reviews of your financial plans are conducted as an integral part
									of NEO's service, ensuring your financial plan is up-to-date and matches your current and future financial needs
								</p>
							</div>
						</div>
					</section>
				</Container>
			</StyledFifthSection>
		</PageBase>
	);
}

const StyledFirstSection = styled.section`
	background-color: #f8f9fb;
	overflow-x: hidden;

	& .container {
		flex-direction: column;

		& .Button {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		@media screen and (min-width: 768px) {
			flex-direction: row;
			height: 500px;
			align-items: center;

			& .Button {
				justify-content: flex-start;
			}
		}

		& .header-content {
			padding-bottom: 50px;
			@media screen and (min-width: 768px) {
				width: 50%;
			}
		}

		h2 {
			text-align: center;
			color: #192851;
			font-weight: 800;

			@media screen and (min-width: 768px) {
				font-size: 51px;
			}

			span {
				font-size: 44px;
				font-weight: 700;
				color: #823fd3;

				@media screen and (min-width: 768px) {
					font-size: 88px;
				}
			}

			@media screen and (min-width: 768px) {
				text-align: left;
			}
		}

		p {
			color: #49667b;
			font-size: 14px;
			line-height: 25px;
			text-align: center;
			margin-top: 25px;

			@media screen and (min-width: 768px) {
				text-align: left;
				max-width: 380px;
			}
		}

		& .partner-with-us-btn {
			text-align: center;
			width: 50%;
			display: block;
			margin: 25px auto 0 auto;

			@media screen and (min-width: 768px) {
				display: block;
				width: 130px;
				margin: 25px 0 0 0;
			}

			a {
				color: white;
				font-size: 16px;
				font-weight: 700;
				text-decoration: none;
			}
		}

		img {
			margin-top: 21px;
			height: auto;
			width: 70%;
			display: block;
			margin: 0 auto;

			@media screen and (min-width: 768px) {
				height: 100%;
				width: auto;
				min-height: 500px;
			}
		}
	}
`;

const StyledSecondSection = styled.section`
	padding: 50px 0;
	& .Button {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	& .what-we-do {
		h2 {
			font-size: 28px;
			font-weight: 800;
		}
	}

	@media screen and (min-width: 768px) {
		padding: 50px 0;

		& .what-we-do {
			h2 {
				font-size: 46px !important;
			}
		}

		& .container {
			flex-direction: column;
		}

		& .Button {
			justify-content: flex-start;
		}

		& .what-we-do {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			p {
				text-align: center;
			}
			.learn-more-about-us-btn {
				width: 200px;

				a {
					white-space: nowrap;
				}
			}
		}
	}

	@media screen and (min-width: 1120px) {
		.container {
			flex-direction: row !important;

			& .what-we-do {
				align-items: flex-start;
				& p {
					text-align: left;
					max-width: 380px;
				}
			}
		}
	}

	& .what-we-do-offers {
		margin-top: 50px;
		@media screen and (min-width: 768px) {
			margin-top: 50px;
			width: 100%;

			ul {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				li {
					width: 50%;

					p {
						margin-top: 12.5px !important;
					}
				}
			}
		}

		h2 {
			color: #192851;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 700;
		}

		ul {
			list-style: none;
			margin-top: 45px;

			& li {
				display: flex;
				justify-content: flex-start;

				& p {
					font-size: 14px;
					color: #2b4659;
					line-height: 22px;
				}
			}
		}
	}

	h2 {
		color: #192851;
		font-size: 28px;
		font-weight: 700;
	}

	p {
		color: #49667b;
		font-size: 14px;
		margin-top: 25px;
		line-height: 1.79;
	}

	& .learn-more-about-us-btn {
		text-align: center;
		margin-top: 25px;

		a {
			color: white;
			font-size: 16px;
			font-weight: 700;
			text-decoration: none;
		}
	}
`;

const StyledThirdSection = styled.section`
	background-color: #f8f9fb;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	h2 {
		font-size: 28px;
		color: #192851;
		font-weight: 800;
	}

	p {
		margin-top: 25px;
		color: #49667b;
		font-size: 14px;
		line-height: 1.89;
	}

	& .how-we-help-list {
		list-style: none;
		margin-top: 30px;

		& li {
			font-size: 13px;
			color: #2b4659;
			font-weight: 400;
			margin-bottom: 15px;
			display: flex;
			max-width: 90%;
			&:before {
				content: '-';
				color: #42ceff;
				margin-right: 15px;
			}
		}
	}

	@media screen and (min-width: 1200px) {
		img {
			width: 60%;
		}
	}

	@media screen and (min-width: 768px) {
		flex-direction: row;
		align-items: flex-start;
		padding-bottom: 50px;
		max-height: 800px;
		overflow: hidden;

		& img {
			width: 45%;
		}

		& .container {
			flex-direction: row;
			justify-content: space-between;
			img {
				width: 450px;
				height: auto;
			}

			h2 {
				font-size: 46px;
				padding-top: 50px !important;
			}

			p {
				font-size: 15px;
				max-width: 70%;
			}

			& .how-we-help-content {
				width: 80%;
				margin-left: 75px;
			}
		}
	}
`;

const StyledFourthSection = styled.section`
	background-color: #823fd3;
	padding: 50px;

	img {
		display: block;
		margin: 0 auto;
		margin-bottom: 37px;
		height: 161px;
		width: auto;
	}

	h2 {
		color: white;
		font-size: 32px;
		font-weight: 800;
		text-align: center;
		line-height: 1.28;
	}

	p {
		font-size: 14px;
		color: white;
		font-weight: 400;
		line-height: 1.57;
		margin-top: 25px;
		text-align: center;
	}

	& .chat-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		& .send-us-a-message-btn,
		& .call-us-btn {
			text-align: center;
			margin-top: 12.5px;

			a {
				color: white;
				font-size: 15px;
				font-weight: 700;
				text-decoration: none;
			}
		}
	}

	@media screen and (min-width: 1290px) {
		& .curious-content {
			flex-direction: row !important;
		}

		& .chat-buttons {
			justify-content: center;
			margin-top: 0 !important;
			flex-direction: column !important;
		}
	}

	@media screen and (min-width: 768px) {
		padding: 25px 0;
		min-height: 200px;
		& .container {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			img {
				margin: 0;
				min-height: 200px;
				height: 100%;
				width: auto;
			}

			& .curious-content {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				width: 100%;

				& .chat-buttons {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					margin-top: 25px;

					& .partner-with-us-btn {
						margin-top: 0 !important;
					}

					& > div {
						margin-right: 25px;
					}
				}

				& .curious-text {
					width: 100%;

					h2 {
						text-align: left;

						br {
							display: none;
						}
					}
					p {
						text-align: left;
						width: 100%;
					}
				}
			}
		}
	}

	@media screen and (min-width: 992px) {
		padding: 25px 0;
	}
	@media screen and (min-width: 1291px) {
		padding: 0;
	}
`;
// TODO: Find how to use &::before element only on the first line of the breaking text (line 208)

const StyledFifthSection = styled.section`
	padding: 50px 0;

	h2 {
		font-size: 24px;
		font-weight: 800;
		color: #192851;
	}

	p {
		color: #49667b;
		line-height: 25px;
		font-size: 14px;
		margin: 25px 0 50px 0;
		max-width: 90%;
	}

	@media screen and (min-width: 450px) {
		& .learn-more-about-us-btn {
			display: flex;
			justify-content: center;
			align-items: center;

			a {
				margin: 0 auto;
			}
		}
	}

	& .focus-list {
		&-desktop-number {
			display: none !important;
		}

		&-item-header {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			h2 {
				text-align: left;
			}

			& .focus-list-item-number {
				margin-right: 20px;
			}
		}

		margin-top: 25px;
		&-item {
			margin-bottom: 25px;
			display: flex;
			flex-direction: row;

			&-content {
				h2 {
					font-size: 25px;
					line-height: 35px;
					color: #49667b;
					text-transform: uppercase;
					font-weight: 400 !important;
				}
				p {
					margin: 15px 0 0 0 !important;
					max-width: 100% !important;
				}
			}

			&-number {
				font-size: 44px;
				color: #3cc1e2;
				font-weight: 900;
				border-radius: 100%;
				box-shadow: 0 9px 16px 0 rgba(43, 70, 89, 0.09);
				min-width: 78px;
				width: 78px;
				height: 78px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: white;
				margin-right: 0;
				margin-bottom: 15px;

				&-wrapper {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}

	@media screen and (min-width: 992px) {
		& .container {
			flex-direction: row !important;

			& .focus-list-mobile-number {
				display: none !important;
			}

			& .focus-list-desktop-number {
				display: flex !important;
				margin-right: 25px;
				align-items: flex-start;
			}
			& .focus-content {
				h2 {
					text-align: left !important;
				}

				p {
					width: 380px;
					text-align: left !important;
					margin: 25px 0 !important;
				}

				.learn-more-about-us-btn {
					width: 280px !important;
					margin: 50px 0 0 0 !important;
				}
			}

			& .focus-list {
				width: 50%;
			}
		}
	}

	@media screen and (min-width: 768px) {
		& .container {
			align-items: center;
			justify-content: space-between;
			flex-direction: column;
			& .focus-content {
				h2 {
					font-size: 46px;
					text-align: center;
				}
				p {
					text-align: center;
					margin: 25px auto 25px auto;
				}

				.learn-more-about-us-btn {
					width: 380px;
					display: block;
					margin: 0 auto;
				}
			}
		}
	}
`;
